<template>
    <v-dialog v-model="openDialogSubmissions" fullscreen scrollable>
        <v-card>
            <v-toolbar tile flat dark  class="bg-gradient">
                <v-toolbar-title>{{$t('recapSubmissions')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined depressed small class="mr-2" @click="downloadExport">{{$t('exportsubmissions')}}</v-btn>
                <v-btn outlined depressed small class="mr-2" @click="$emit('closeDialog')">{{$t('cancelLabel')}}</v-btn>
            </v-toolbar>
            <v-card-text style="display:flex; flex-wrap: wrap; justify-content: space-around; padding-top:50px;">
                 <v-card class="answerText" width="48%" :max-height="['checkbox', 'tagbox', 'rating'].includes(column.type) ? '100%' : '550'" v-for="(column, index) in columns" :key="column.name">
                    <v-card-title>{{ column.label }} </v-card-title>
                    <v-card-subtitle>{{ column.countAnswers }} {{ $t('answers') }}</v-card-subtitle>
                    <v-card-text style="overflow:auto;">
                        
                        <v-list v-if="['radiogroup', 'dropdown'].includes(column.type)">
                            <PieComponent  style="min-height:190px;" :dataSet="column.dataset"/>
                        </v-list>
                        <div v-else-if="['checkbox', 'tagbox', 'rating'].includes(column.type)" style="min-height:190px;">
                            
                            <HorizontalBarComponent style="min-height:190px;" :maintainAspectRatio="false" :dataSet="column.dataset"  />
                        </div>
                        <div v-else>
                            <v-list-item dense style="background-color: #EEE; border-radius:5px; margin-bottom:5px;" v-for="(value, index) in column.values" :key="index">
                                <v-list-item-content> 
                                    <v-list-item-title>{{ value }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            
                        </div>
                    </v-card-text>
                 </v-card>

                 <v-dialog persistent width="400" v-model="showDownload">
                    <v-card>
                        <v-card-text class="pa-6">
                            <h5 class="icon-title">
                            {{$t('downloadReady')}}
                            <v-btn @click="showDownload = false" text class="square">
                                <v-icon small>$close</v-icon>
                            </v-btn>
                            </h5>
                            <div class="text-center mt-4 mb-2">
                                <a :href="downloadUrl" @click="showDownload = false" download class="exportBtn">{{$t('clickForDownload')}}</a>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script> 
import GenericDataService from '@/services/GenericDataService'; 
import HorizontalBarComponent from './graphs/HorizontalBarComponent.vue'; 
import PieComponent from './graphs/PieComponent.vue'; 
export default {
    name: "submissionDialogComponent",
    props: {
        openDialogSubmissions: Boolean,
        formId : String
    },
    components: { HorizontalBarComponent, PieComponent },
    data() {
        return {
            default_colors: ['#347ab6', '#5bb85d', '#efad4d', '#d9544f', '#6208ff', '#ff2987', '#8336ff', '#5f04ff', '#161459', '#27c7d4', '#E3C034', '#B7B7B7', '#CCA76E', '#436E9E', '#1E4169', '#294f79', '#388d3c', '#0388d0', '#1465c0', '#2f3f9e', '#9e9e9e'],
            submissionData: null,
            columns: [],
            data: {
                
                "label": "conversionBtnTitle",
                "labels": ["1", "2", "3"],
                "datasets": [
                    { "label": "leads", "to_trad": true, "data": [4, 5, 10], "backgroundColor": "#F2BB00","notFilterable": true },
                    
                ]
            },
            downloadUrl : "",
            api: process.env.VUE_APP_API_URL,
            showDownload : false
        };
    },
    mounted() {
        this.getSubmissions();
    },
    watch: {
        openDialogSubmissions: function (val) {
            console.log('openSub', val);
            if(val){
                this.getSubmissions();
            }
        }
    },
    methods: {
        downloadExport(){
            GenericDataService.getData("/form/"+this.formId+"/exportSubmissions").then((response) => {
                if(response.data.data.directDownload){
                    this.downloadUrl = this.api + response.data.data.directDownload;
                    this.showDownload = true;
                }
            });
        },
        getSubmissions(){
            //GenericDataService.getData("/form/getSubmissions")
            GenericDataService.getData("/form/showSubmissions?id=" + this.formId).then((response) => {
                this.submissionData = response.data.data;
                GenericDataService.getData("/form/"+this.formId+"/exportSubmissions?onlyColumns=true").then((response) => {
                    this.columns = response.data.data;
                    this.columns.forEach((element) => {
                        if(!element.hasOwnProperty('values')){
                            element.values = [];
                        }
                        element.countAnswers = 0;
                        this.submissionData.forEach((submission) => {
                            submission.data.forEach((data) => {
                                if(data.name == element.name){
                                    element.type = data.type;
                                    element.countAnswers++;
                                    if(Array.isArray(data.value)){
                                        data.value.forEach((value) => {
                                            element.values.push(value.text ? value.text : value);
                                        });
                                    }else{
                                        element.values.push(data.value.text ? data.value.text : data.value);
                                    }
                                }
                            });
                        });
                    });
                    //construction du dataset 
                    this.columns.forEach((column) => {
                        if(column.type == 'checkbox' || column.type == 'tagbox' || column.type == 'rating' || column.type == 'radiogroup' || column.type == 'dropdown'){
                            let dataset = {};
                            dataset.labels = [];
                            //column.label;
                            dataset.datasets = [];

                            let predata = {};
                            column.values.forEach((value) => {
                                if(!predata.hasOwnProperty(value)){
                                    predata[value] = 0;
                                }
                                predata[value]++;
                            }); 
                            let dataValue = [];
                            let percents = [];
                            let bgColors;
                            for (const key in predata) {
                                if (Object.prototype.hasOwnProperty.call(predata, key)) {
                                    const count = predata[key];
                                    dataset.labels.push(key);
                                    dataValue.push(count);
                                    let percent = ((count * 100 / column.countAnswers + "").length > 3) ? (count * 100 / column.countAnswers).toFixed(2) : (count * 100 / column.countAnswers);
                                    percents.push(percent);
                                }
                            }
                            
                            if(column.type == 'radiogroup' || column.type == 'dropdown'){
                                bgColors = this.default_colors.slice(0, dataValue.length);
                            } else {
                                bgColors = "#161459";
                            }
                            dataset.datasets.push({label: column.label, data: dataValue, percents:percents, backgroundColor: bgColors, barThickness : 25});
                            column.dataset = dataset;
                            // this.submissionData.forEach((submission) => {
                            //     submission.data.forEach((data) => {
                            //         if(data.name == column.name){
                            //             data.value.forEach((value) => {
                            //                 let index = column.values.indexOf(value);
                            //                 dataset.data[index]++;
                            //             });
                            //         }
                            //     });
                            // });
                            // this.data.datasets.push(dataset);
                        }
                    });
                });
            
                
            });
        }
    },
};
</script>
<style lang="scss">
    .answerText {
        display:flex;
        flex-direction: column;
        margin-bottom:25px;
        .v-card__text {
            flex-grow:1;
            overflow:auto;
        }
    }
</style>