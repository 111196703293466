<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  props: ['dataSet', 'legend', 'percent_color', 'disableTooltip', 'percent_total'],
  data() {
      return {
          options: {
            responsive:true,
            maintainAspectRatio: false, 
            onClick:this.handle,
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            legend: {
                display: this.legend == false ? false : true,
                position: 'bottom',
                labels: {
                    usePointStyle: true
                },
                onClick: this.handleLegend,
                onHover: function(event, legendItem) {
                   var chart = this.chart;
                    var index = legendItem.index;
                    var segment = chart.getDatasetMeta(0).data[index];
                    chart.tooltip._active = [segment]
                    chart.tooltip.update()
                    chart.draw()
                },
                onLeave: function(event, legendItem) {
                    var chart = this.chart;
                    var index = legendItem.index;
                    chart.tooltip._active = []
                    chart.tooltip.update()
                    chart.draw()
                }
            },
            tooltip: {
                enabled: this.disableTooltip ? false : true
            },
            title:{
                display: false,
                text: "test"
            },
            animation: {
                duration:0,
            },
            scales: {
                
            },
            tooltips: {
                enabled: this.disableTooltip ? false : true,
                callbacks: {
                    label: function(tooltipItem, data) {
                        console.log(tooltipItem, data)
                        return data['labels'][tooltipItem['index']] + ' - '+data['datasets'][0]['percents'][tooltipItem['index']]+'%';
                    }
                },
            }
          }
      }
  },
  watch: {
    //   chartData : {
    //       handler(val){
    //         this.renderChart(this.chartData, this.options) 
    //       }
    //   }
  },
  computed: {
      chartData: function() {
          const data = this.dataSet;
          data.datasets.forEach(dataset => {
            let newColors = []
            dataset.backgroundColor.forEach(color => {
                let newColor;
                if(this.$vuetify.theme.themes.light[color]){
                    newColor = this.$vuetify.theme.themes.light[color]
                } else {
                    newColor = color
                }
                newColors.push(newColor)
            })
            dataset.backgroundColor = newColors 
          });
            
          return data
      }
  },
  methods: {
    reRender(){
        this.renderChart(this.chartData, this.options)
    },
    handleLegend(event, legendItem, legend){
        this.$emit('selectItem', legendItem);
    },
    handle (point, event) {
       
    	const item = event[0]
        if(item && item._chart){
            this.$emit('selectItem', item._index);
        }
         
        // this.$emit('on-receive', {
        //     index: item._index,
        //     backgroundColor: item._view.backgroundColor,
        //     value: this.values[item._index]
        // })
    },
    
  },
  created() {
      window.addEventListener('resize', this.reRender);
  },
  destroyed() {
      window.removeEventListener('resize', this.reRender);
  },
  mounted () {
     
      
        setTimeout(() => {
            if(this.chartData.label && this.chartData.label !=""){
                this.options.title.text = this.chartData.label
            } else {
                this.options.title.text = "";
            }
            if(this.$refs.canvas)
                this.renderChart(this.chartData, this.options)  
        }, 50);
        
  },
  
}
</script>

<style lang="scss">
    
</style>